<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 41">
    <path
      fill="url(#a)"
      fill-rule="evenodd"
      d="M37.581 9.165a3.534 3.534 0 0 0-5.886 3.913 13.35 13.35 0 0 1 2.237 7.424c0 7.417-6.013 13.43-13.43 13.43a13.35 13.35 0 0 1-7.423-2.237 3.534 3.534 0 1 0-3.914 5.886 20.419 20.419 0 0 0 11.337 3.418C31.822 41 41 31.822 41 20.502c0-4.186-1.259-8.089-3.419-11.337ZM20.498 26.154a5.655 5.655 0 1 0 0-11.31 5.655 5.655 0 0 0 0 11.31Z"
      clip-rule="evenodd"
    />
    <path
      fill="#fff"
      fill-rule="evenodd"
      d="M20.498 7.068c-7.417 0-13.43 6.013-13.43 13.43a3.534 3.534 0 0 1-7.068 0C0 9.178 9.177 0 20.498 0a3.534 3.534 0 0 1 0 7.068Z"
      clip-rule="evenodd"
    />

    <defs>
      <linearGradient
        id="a"
        x1="12.491"
        x2="39.949"
        y1="21.613"
        y2="39.809"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2DE8B0" />
        <stop offset=".488" stop-color="#CBE953" />
        <stop offset=".759" stop-color="#FFAB48" />
        <stop offset="1" stop-color="#FF5151" />
      </linearGradient>
    </defs>
  </svg>
</template>
